































































































































































































































































































































































































































































































import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
// @ts-ignore
import { CIQ } from 'chartiq/js/componentUI';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getComponent } from '@/utils/helpers';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import setpointModule from '@/store/modules/setpointModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import localForage from '@/lib/localForage';
import workflowModule from '@/store/modules/workflowModule';
import { getCustomConfig } from './resources';
// ChartIQ library resources
let globalStx = CIQ.ChartEngine;
@Component({
  components: {
    Dropdown: () => getComponent('common/Dropdown'),
  },
})
export default class AdvancedChartComponent extends Vue {
	@Prop() config: any

	@Prop({ type: Function, default: ({}) => {} }) chartInitialized!: Function

	@Ref('container') container!: HTMLElement

	selectedSignals: any = []

	avaialbleDesription: any = [];

  marker: any = [];

  availableSignalData: any = [];

	stx: CIQ.ChartEngine | undefined

		dropdownLocation: any[] = []

		hideDropdown() {
		  // console.log(this.selectedSignals);

			 for (const s in this.stx.chart.series) {
		    const series = this.stx.chart.series[s];
		    this.stx.removeSeries(series);
		  }
		  this.dropdownLocation = [];
		  const activeSeries = this.selectedSignals.map((s) => s.value);
		  if (activeSeries) {
		  this.addCustomSeries(this.selectedSignals.map((s) => s.value));
		  }
		}

		async didSelectDropdownOption() {
		  console.log(this.stx.drawingObjects);

		  // ts-ignore
		}

		didSelectDropdown(e) {
		// if (type == 'Status Changes' || type == 'Duplicate Tasq') {
		  console.log(e);
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
		  this.dropdownLocation = [rect.x - 80, rect.y];

		  // } else {
		  // 	const rect = e.srcElement.getBoundingClientRect()
		  // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
		  // }
		}

		get avaialbleDataPointNames() {
		  return this.availableSignalData.map((dataPoint) => ({ value: dataPoint.name, text: dataPoint.name, type: dataPoint.type }));
		}

		addCustomSeries(series) {
	  const colors = ['red', 'green', 'blue', 'orange', 'purple', 'yellow', 'pink', 'skin', 'brown'];
	  const axis = new CIQ.ChartEngine.YAxis({ position: 'right' });
	  this.stx.chart.legend = {
	    x: 300,
	    y: 20,
	  };

		  const visibleSeries = this.availableSignalData.filter((dataPoint) => series.includes(dataPoint.name));

		  if (visibleSeries) {
		    visibleSeries.forEach((dataPoint, i) => {
		      this.stx.addSeries(dataPoint.name, {
	        color: colors[i],
	        data: dataPoint.data,
	        fillGaps: true,
	     
          gapDisplayStyle: true,
	 renderer: 'Lines',
	        type: 'lines',
	        marginTop: 100, // give room for the legend
	      });
		    });
		  }

      this.stx.draw()
		}

		prepareSetpointData() {
		  if (setpointModule.adminSetpointData) {
		     this.availableSignalData = this.availableSignalData.concat(setpointModule.adminSetpointData);
		  }
		}

		async prepareSignalProdData() {
		  const tasqAvailableSignals = tasqSignalsModule.currentSignals;
	  if (tasqAvailableSignals) {
	    tasqAvailableSignals.forEach((currentSignal, i) => {
	     const dataPoint = currentSignal.dataset.map((d, i) => ({
	        Date: (new Date(currentSignal.time[i])).getTime(),
		        DT: ( currentSignal.time[i]),
	        Open: d,
	        Arrival: d,
	        Close: d,
	        Low: d,
	        High: d,
	        Volume: d,
		      }));

		      this.availableSignalData.push({
		        name: currentSignal.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'signal',
		      });
	    });
	  }

		  // console.log(this.availableSignalData);

      	    const availableProdData: any = tasqProductionDataChartModule.adminChartProductionData;
		    const availableProdDataPoints = availableProdData.dataset.filter((dataset) => dataset.data && dataset.data.length);

		    if (availableProdDataPoints) {
	    availableProdDataPoints.forEach((currentProdType, i) => {
	      const dataPoint = currentProdType.data.map((d, i) => ({
		          Date: new Date(availableProdData.date[i]).toISOString(),
	        Open: Number.parseFloat(d),
	        Arrival: Number.parseFloat(d),
	        Close: Number.parseFloat(d),
	        Low: Number.parseFloat(d),
	        High: Number.parseFloat(d),
	        Volume: Number.parseFloat(d),
		      }));

		      this.availableSignalData.push({
		        name: currentProdType.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'production',
		      });
	    });
	  }
		}

		get events() {
		  return tasqsListModule.tasqEventHistoryList.map((event) => ({
		      x: new Date(event.time),
		      type: 'circle',
		      category: 'news',
		      headline: event.readable_string,
		    }));
		}

		async mounted() {
	  const config = this.config || getCustomConfig();

	  const { container } = this;
	  portalizeContextDialogs(container);
	  // Delay the call to createChartAndUI so any other AdvancedChart components on the page
	  // have a chance to call portalizeContextDialogs
	  window.setTimeout(async () => {
	    const uiContext = this.createChartAndUI(config);
	    this.stx = uiContext.stx;
		    globalStx = this.stx;

	    const axis = new CIQ.ChartEngine.YAxis({ position: 'left' });

		    const tasqAvailableSignals = tasqSignalsModule.currentSignals;

		 this.avaialbleDesription = tasqAvailableSignals.map((s, i) => ({
				 text: s.name,
				 value: s.name,
			 }));

	    this.stx.chart.legend = {
	      x: 300,
	      y: 20,
	    };
	  const colors = ['red', 'green', 'blue', 'orange', 'purple', 'yellow', 'pink'];
		    //   const axis = new CIQ.ChartEngine.YAxis({ position: 'left' });
	  this.stx.chart.legend = {
	    x: 300,
	    y: 20,
	  };
		    // tasqAvailableSignals = tasqSignalsModule.currentSignals.filter((s) => s.name === 'Flowrate');
		    //   const tasqAvailableSignals = tasqSignalsModule.currentSignals.filter((s) => s.name !== 'Flowrate');
		    //   if (tasqAvailableSignals) {
		    //     tasqAvailableSignals.forEach((currentSignal, i) => {
		    //       const currentSignalData = currentSignal.dataset.map((d, i) => ({
		    //         Date: currentSignal.time[i],
		    //         Open: d,
		    //         Arrival: d,
		    //         Close: d,
		    //         Low: d,
		    //         High: d,
		    //         Volume: d,
		    //       }));

		    // 	        // console.log(currentSignalData);

		    //       // s one has data matching the range of the primary symbol
		    //       // and we are also appending new ticks to keep it in sync
		    //       this.stx.addSeries(currentSignal.name, {
		    //         color: colors[i],
		    //         data: currentSignalData,
		    //  renderer: 'Lines',
		    //         type: 'lines',
		    //         marginTop: 100, // give room for the legend
		    //       });
		    //     });
		    //   }

		    this.prepareSignalProdData();
		    this.prepareSetpointData();

		    //   this.selectedSignals.push({ value: 'Flowrate', text: 'Flowrate' });
		    uiContext.stx.setPeriodicity({ period: 1, interval: 60, timeUnit: 'minute' });
		    uiContext.stx.setSpan({
		      multiplier: 1,
		      base: 'hour',
		      padding: 0,
		      periodicity: {
		        interval: 60,
		        period: 1,
		        timeUnit: 'minute',
		      },
		    });
		    uiContext.stx.setChartType('line');
		    // ts-ignore

		    // this.stx.draw();

		    // signal the chart that a drawing has been added...so you can save the layout if you want.
		    console.log(config);
		    this.stx.changeOccurred('vector');
	    this.chartInitialized({ chartEngine: uiContext.stx, uiContext, config });

		    this.addCustomSeries(['Static Pressure','Casing Pressure', 'Tubing Pressure', 'Flowrate', 'Static Pressure']);
	  }, 0);
		}

		async showAnnotations() {
		  console.log(this.stx.drawingObjects);
		  if (this.stx.drawingObjects && this.stx.drawingObjects.length) {
		    this.stx.drawingObjects = [];
		  } else {
		    const drawingObjPayload: any = await workflowModule.getChartDrawingDetails({ nodeID: tasqsListModule.activeTasq?.wellName });

		    // add it to the chart
		    this.stx.importDrawings(drawingObjPayload);

		  this.stx.draw();
		  }
		}

		async beforeDestroy() {
		// Destroy the ChartEngine instance when unloading the component.
		// This will stop internal processes such as quotefeed polling.
		  // const drawing = this.stx.drawingObjects;
		  // ts-ignore

		  // ts-ignore
		  const data = (this.stx.exportDrawings());
		  const drawing = data.map((d) => JSON.stringify(d));
		  // console.log(drawing[0]);
		  // console.log(JSON.stringify(drawing[0]))
		  if (data.length) {
		    // ts-ignore
		    await workflowModule.postChartDrawingDetails({ nodeId: tasqsListModule.activeTasq?.wellName, payload: drawing });
		  }
		  //  window['drawingObj'] = drawing
		  // await localForage.setItem('drawing', drawing);
		this.stx?.destroy();
		}

		createChartAndUI(config: any) {
	  // console.log(config);
	  const { container } = this;
	  const chart = new CIQ.UI.Chart();
	  const uiContext = chart.createChartAndUI({ container, config, layout: { crosshair: true, interval: 'week' } });
	  return uiContext;
		}

		get drawingObjects() {
		  return this.stx.drawingObjects;
		}

  @Watch('drawingObjects')
		async updateDrawingObjects(data) {
		  if (data && data.length) {
		    const dataObj = (this.stx.exportDrawings());
		  const drawing = dataObj.map((d) => JSON.stringify(d));
		  // console.log(drawing[0]);
		  // console.log(JSON.stringify(drawing[0]))
		  if (dataObj.length) {
		    // ts-ignore
		    await workflowModule.postChartDrawingDetails({ nodeId: tasqsListModule.activeTasq?.wellName, payload: drawing });
		  }
		  }
		}

  async	showMarkers() {
		  // console.log(this.stx);
		  if (this.marker && this.marker.length) {
		    // const marker = new CIQ.Marker({});
		    // marker.remove()
		    this.marker.forEach((m) => {
		      m.remove();
		    });
		    this.marker = [];
		  } else {
		  // Loop through the data and create markers
		  for (let i = 0; i < this.events.length; i++) {
		    const datum = this.events[i];
		    // datum.story = story;
		    const params = {
		      stx: this.stx,
		      xPositioner: 'date',
		      x: this.events[i].x,
		      label: 'events',
		      node: new CIQ.Marker.Simple(datum),
		    };
		    const marker = new CIQ.Marker(params);
		    // console.log(marker);
		    marker.node.classList.toggle('highlight');
		      this.marker.push(marker);
		  }

		  this.stx.draw();
		  }
  }
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container: HTMLElement) {
  container.querySelectorAll('cq-dialog').forEach((dialog) => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}
function dialogPortalized(el: Element) {
  if (!el.firstChild) {
    throw new Error('Element has no children');
  }
  const tag = el.firstChild.nodeName.toLowerCase();
  const result = Array.from(document.querySelectorAll(tag)).some(
    (el) => !el.closest('cq-context'),
  );
  return result;
}
